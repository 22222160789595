export const filtersChips = { 
  appointmentId: '',
  customerId: '',
  dayOfWeek: '',
  employeeId: '',
  evenDay: null,
  practiceTypeId: null,
  startTime: ''
};
const initialValuesStep0 = {
  startDate: '',
  endDate: '',
  chips: [],
  filters: filtersChips,
  practicesType: [],
  schedules: [],
  schedulesSelected: [],
  schedulesSelectedData: [],
  originalschedule: [],
  originalquery: []
}
const initialValuesStep1 = {
  originalProfessionalsToReassign: [],
  professionalsSelected: [],
  professionalsToReassign: []
}
const initialValuesStep2 = {
  dateTimeSchedules: '',
  dateSchedulesHasChanged: false,
}
export const initialValuesStep3 = {
  scheduleTimeUpdate: [],
  timeSchedules: '',
  timeSchedulesHasChanged: false
}
export const initialValuesStep4 = {}
export const initialValuesStep5 = {
  bookings: [],
  bookingChanged: [],
  bookingsError: [],
  currentEvent: {},
  events: [],
  hasChangedSchedules: false,
  loadingevents: false,
  schedulesNotAssigned: [],
  showConfirmSave: false,
  showConfirmUndo: false
}

export const initialValues = {
  busyButton: false,
  graphics: false,
  ...initialValuesStep0,
  ...initialValuesStep1,
  ...initialValuesStep2,
  ...initialValuesStep3,
  ...initialValuesStep5,
}

export const initialNotification = {
  color: 'warning',
  message: '',
  open: false,
};

export const steps = [
  {
    number: 0,
    label: 'label.appointmentsSchedules',
    isOpcional: false,
    completed: false,
    saveButton: false,
    skipped: false
  },
  {
    number: 1,
    label: 'label.drivers',
    isOpcional: true,
    completed: false,
    saveButton: false,
    skipped: true,
  },
  {
    number: 2,
    label: 'common.date',
    isOpcional: true,
    completed: false,
    saveButton: false,
    skipped: true
  },
  {
    number: 3,
    label: 'label.hours',
    isOpcional: true,
    completed: false,
    saveButton: false,
    skipped: true,
  },
  {
    number: 4,
    label: 'label.requestedChanges',
    isOpcional: false,
    completed: false,
    saveButton: true,
    skipped: false
  },
  {
    number: 5,
    label: 'appointment.agenda',
    isOpcional: false,
    completed: false,
    saveButton: false,
    skipped: false
  },
];

export const getColor = (status) => {
  const colors = {
    1: 'F06292',
    2: '1BD893',
    3: 'D26D0B',
    4: '79009B',
    5: 'D81B60',
    6: '00B359',
    7: 'DEC02B',
    8: 'BF00B0',
    9: '880E4F',
    10: '006E2A',
    11: 'E8DD6C',
    0: 'E157CA'
  };

  return colors[status.group % 12];
}

export const initialAlertWarning = {
  open: false,
  message: '',
  status: 'warning',
  confirmBtnText: '',
  cancelBtnText: '',
  title: '',
  type: ''
}
