import React, { useEffect, useState } from 'react';

import AppointmentApiInvoker from '../../../api/AppointmentApiInvoker';
import Button from '../../CustomButtons/Button';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ConfirmRejectedItem from './ConfirmRejectedItem';
import CustomSweetAlert from '../../CustomSweetAlert';
import PropTypes from 'prop-types';
import Snackbar from '../../Snackbar/Snackbar';
import Table from '../../Table/Table';
import { Tooltip } from '@material-ui/core';
import config from '../../../config/config';
import moment from 'moment';
import { useQuantityRequests as setQuantityRequests } from '../../../hooks/useQuantityRequests';
import { withTranslation } from 'react-i18next';

const dateFormat = config.getDateFormat();

const ItemsRequest = ({ appointment, practiceTypes, onChangeItemsRequest, packages, t }) => {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState({});
  const [showConfirmAcceptItem, setShowConfirmAcceptItem] = useState(false); 
  const [showConfirmRejectItem, setShowConfirmRejectItem] = useState(false); 
  const [confirmAlert, setConfirmAlert] = useState({
    message: '',
    type: 'danger',
    open: false,
  }); 

  const onAcceptItemAction = (id) => {
    setSelectedItemId(id);
    setShowConfirmAcceptItem(true);
  }

  const onRejectItemAction = (id) => {
    setSelectedItemId(id);
    setShowConfirmRejectItem(true);
  }

  const openConfirmAlert = (type, message) => {
    setConfirmAlert({
      type,
      message,
      open: true,
    });

    setTimeout(() => {
      setConfirmAlert({ open: false });
    }, 5000);
  }

  const onAcceptItem = () => {
    AppointmentApiInvoker.AcceptItemRequestMobility(selectedItemId,
      (res) => {
        setShowConfirmAcceptItem(false);
        getItemRequest();
        onChangeItemsRequest(true);
        openConfirmAlert('success', t('confirm.transfer.success.acceptItem'));
      }, (err) => {
        console.error('** Error postAcceptItemRequest: ', err);
        setShowConfirmAcceptItem(false);
        openConfirmAlert('danger', t('confirm.error'));
      });
  }

  const onRejectItem = (isRejected) => {
    if (isRejected) {
      getItemRequest();
      onChangeItemsRequest(true);
    }
  }

  const cancelAppointmentAction = (r) => {
    const body = {
      "reason": {r},
      "appointmentDetailIds": [],
    }
    if(!r){
      openConfirmAlert('danger', t('rejectedReasonTransfer.error'));
    }else{
      AppointmentApiInvoker.cancelAppointmentMobility(selectedItemId, body, () => {
        setShowConfirmAcceptItem(false)
        setShowConfirmRejectItem(false)
        getItemRequest();
          onChangeItemsRequest(true);
      }, () => {
        setShowConfirmAcceptItem(false)
        setShowConfirmRejectItem(false)
        getItemRequest();
          onChangeItemsRequest(true);
      });
    }
    
  }

  const renderActionItemRequestComponent = (a) => {
    return (
      <div className="appointment-action">
        
        <Tooltip title={ t('common.accept') }>
          <span>
            <Button
              id="button-accept"
              simple
              justIcon
              color="success"
              onClick={() => onAcceptItemAction(a.transferItemRequestId)}
              //disabled={a.driver ? false : true}
            >
              <CheckIcon />
            </Button>
          </span>
        </Tooltip>
        
        <Tooltip title={t('common.reject')}>
          <span>
            <Button
              id="button-reject"
              simple
              justIcon
              color="secondary"
              onClick={() => onRejectItemAction(a.transferItemRequestId)}
            >
              <ClearIcon style={{ color: 'grey' }} />
            </Button>
          </span>
        </Tooltip>
      </div>
    );
  }

  const isMeModulePractice = (partnership) => {
    const isLocalStorageEmpty = localStorage.getItem('company_partnership_id') === null;
    return !partnership || isLocalStorageEmpty || partnership.companyId === parseInt(localStorage.getItem('company_partnership_id'));
  };

  const getLenderName = (partnership) => {
    const isMe = isMeModulePractice(partnership);
    const lenderName = isMe ? (partnership?.name ?? '') : t('common.other');
    return lenderName;
  }

  const getItemRequestName = (r) => {
    let practiceTypeId = null;
    let practiceItem = {};
    let packageId = null;
    let packageItem = {};
    
    if (r.itemRequestDetails) {
      practiceTypeId = r.practiceTypeId;
      practiceItem = practiceTypes.find(e => e.practiceTypeId === practiceTypeId);
    }
    if (r.itemRequestPackageDetails) {
      packageId = r.packageId;
      packageItem = packages.find(e => e.packageId === packageId);
    }

    const packageName = packageId ? packageItem.name : '';
    
    return practiceTypeId ? practiceItem.name : packageName;
  }

  const formatItemRequest = (itemRequest, appointmentEndDate, companyPartnershipId) => 
   
    itemRequest.map((r, index) => {
      const practiceItem = practiceTypes.find(e => e.practiceTypeId == r['practiceTypeId']);
      const itemStartDate = moment(r.startDate);
      
      const type = t('Traslado');
      const lender = r.companyIdPartnership ? getLenderName(r.companyIdPartnership) : '';
      
      let min = 60;
      if (practiceItem != null) {
        min = practiceItem['estimatedDuration']
      }

      if (r.schedulePlan?.processingScheduleType == 'INTRADAY') {
        r.schedulePlan.quantityIntraday = r.schedulePlan.processingDurationTime / min;
        r.schedulePlan.recurrenceType = 'INTRADAY';
      }

      let edit = false;
      if (r.companyIdPartnership && r.companyIdPartnership.companyId === companyPartnershipId) {
        edit = true;
      }

      return {
        order: index+1,
        type,
        //name,
        startDate: moment(r.startDate).format(dateFormat),
        //quantity,
        lender,
        driver:r.driver ? (r.driver.firstName + ' ' +r.driver.lastName) : 'Sin asignar', 
        status: t(`status.appointmentItemRequest.${r.transferItemRequestStatus}`) || '',
        //actions: edit && r.transferItemRequestStatus === 'CREATED' ? renderActionItemRequestComponent(r) : '',
        actions: edit && r.transferItemRequestStatus === 'CREATED' ? renderActionItemRequestComponent(r) : '',
      }
    }
  )


  const formatDataTableApp = (itemRequest, appoint) => {
    let companyPartnershipId = -1;
    if (localStorage.getItem('company_partnership_id') != null) {
      companyPartnershipId = parseInt(localStorage.getItem('company_partnership_id'));
    } 
    if (companyPartnershipId == null || companyPartnershipId === -1) {
      companyPartnershipId = parseInt(localStorage.getItem('itlg_default_company_id'));
    }
    const appointmentEndDate = moment(appoint.endDate, dateFormat).format('YYYY/MM/DD');
    const formatItems = formatItemRequest(itemRequest, appointmentEndDate, companyPartnershipId);
    const orderItems = formatItems.sort((a, b) => a.order - b.order);
    return orderItems;
  }
  
  const getItemRequest = () => {
    setLoading(true);
    AppointmentApiInvoker.getAppointmentById(appointment.appointmentId, (res) => {
      const data = formatDataTableApp(res.items, appointment);
      setItems(data);
      setLoading(false);
    }, (err) => {
      console.error('** Error getAppointmentItems:', err);
      setLoading(false);
    })
  }

  useEffect(() => {
    onChangeItemsRequest(false);
    getItemRequest();
    return () => {
      setItems([]);
      onChangeItemsRequest(false);
    }
  }, []);

  return (
    <>
      <Table
        filterable
        loading={loading}
        tableHeaderColor="primary"
        sortable
        tableHead={[
          { Header: t('appointment.table.orden'), accessor: 'order' },
          { Header: t('appointment.new.schedule.practice.lender'), id: 'lender', accessor: 'lender' },
          { Header: t('appointment.table.type'), accessor: 'type' },
          //{ Header: t('appointment.table.name'), accessor: 'name' },
          { Header: t('appointment.table.status'), accessor: 'status' },
          { Header: t('appointment.table.startDate'), accessor: 'startDate' },
          { Header: t('appointment.table.driver'), accessor: 'driver' },
          { Header: t('appointment.table.actions'), accessor: 'actions' },
        ]}
        tableData={items}
        colorsColls={['primary']}
        className="items-request-component -striped -highlight filtrable sticky"
        defaultPageSize={items.length <= 25 ? 25 : items.length}
        showPaginationTop={false}
        showPaginationBottom={false}
      />

      {showConfirmAcceptItem &&
        <CustomSweetAlert
          type="warning"
          onConfirm={() => onAcceptItem()}
          onCancel={() => setShowConfirmAcceptItem(false)}
          title={t('transferAppointment.item.accept.title')}
          confirmBtnCssClass="primary"
          cancelBtnCssClass="danger"
          cancelBtnText={t('appointment.created.cancel.cancel')}
          confirmBtnText={t('appointment.created.cancel.confirm')}
          showCancel={true}
          message={<p>{t('transferAppointment.item.accept.message')}</p>}
        />
      }

      {showConfirmRejectItem &&
        <ConfirmRejectedItem
          selectedItemId={selectedItemId}
          setShowConfirmRejectItem={setShowConfirmRejectItem}
          onRejectItem={onRejectItem}
          cancelAppointmentAction={cancelAppointmentAction}
        />
      }
      {/*showConfirmRejectItem &&
          <CustomSweetAlert
            type="warning"
            onConfirm={cancelAppointmentAction}
            onCancel={() => setShowConfirmRejectItem(false)}
            confirmBtnCssClass="primary"
            cancelBtnCssClass="danger"
            title={t('appointment.created.cancel.title')}
            cancelBtnText={t('appointment.created.cancel.cancel')}
            confirmBtnText={t('appointment.created.cancel.confirm')}
            showCancel={true}
            message={<p>{t('appointment.created.cancel.message')} {selectedItemId}</p>}
          />
        */}
      <Snackbar
        color={confirmAlert.type}
        message={confirmAlert.message}
        open={confirmAlert.open}
        place="tr"
      />
    </>
  )
}

ItemsRequest.defaultProps = {
  onChangeItemsRequest: () => {},
  packages: [],
  practiceTypes: [],
}

ItemsRequest.propTypes = {
  t: PropTypes.func,
  appointment: PropTypes.object.isRequired,
  onChangeItemsRequest: PropTypes.func,
  packages: PropTypes.array,
  practiceTypes: PropTypes.array,
}

export default withTranslation()(ItemsRequest);
