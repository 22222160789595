import "./AppointmentDetail.css";

import { Calendar, momentLocalizer } from "react-big-calendar";
import React, { Component } from "react";

import _ from 'lodash';
import Add from "@material-ui/icons/Add";
import AppointmentApiInvoker from "../../../api/AppointmentApiInvoker";
import Assignment from "@material-ui/icons/Assignment";
import BusinessApiInvoker from "../../../api/BusinessApiInvoker";
import Button from "../../CustomButtons/Button";
import CalendarToday from "@material-ui/icons/CalendarToday";
import Card from "../../Card/Card";
import CardBody from "../../Card/CardBody";
import CardFooter from "../../Card/CardFooter";
import CardHeader from "../../Card/CardHeader";
import CardIcon from "../../Card/CardIcon";
import CustomInput from "../../CustomInput/CustomInput";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import EventWithIcon from "../../EventWithIcon/EventWithIcon";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";
import ModalDetailServiceTraslado from "../Traslado/ModalDetailServiceTraslado";
import PersonApiInvoker from "../../../api/PersonApiInvoker";
import PropTypes from "prop-types";
import SchedulerService from "../../../containers/SchedulerService";
import Table from "../../Table/Table";
import Tooltip from "@material-ui/core/Tooltip";
import ValidationInput from "../../ValidationInput";
import ViewList from "@material-ui/icons/ViewList";
import { browserHistory } from "react-router";
import memory from "../../../config/memory";
import moment from "moment";
import withStyles from "@material-ui/core/styles/withStyles";
import { withTranslation } from "react-i18next";
import FullLoading from "../../FullLoading";
import CustomSweetAlert from "../../CustomSweetAlert";
import config from "../../../config/config";
import CustomDialog from "../../CustomDialog";
import SelectInput from "../../SelectInput";

require("moment/locale/es.js");
const dateFormat = config.getDateFormat();
const dateFormatServer = config.getDateToServer();
const localizer = momentLocalizer(moment);

class AppointmentDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      metadata: {
        medicalData: {},
        diagnostic: {},
      },
      types: [
        { value: 'Sólo ida', id: 1 },
        { value: 'Sólo vuelta', id: 2 },
        { value: 'Ida y vuelta', id: 3 },
      ],
      cancellations: [],
      cancellationReason: "",
      confirmMetadataEdit: false,
      practiceModulesCopy: [],
      loading: false,
      openDetail: false,
      saveChangesDisabled: false,
      bookings: [],
      openConfirmCancel: false,
      openConfirmChanges: false,
      currentEvent: {},
      items: [],
      itemsCopy: [],
      events: [],
      event_original: [],
      updateErrors: {},
      status: "",
      alertErrorOpen: false,
      alertErrorMessage: "",
      alertStatus: "",
      alertOpen: false,
      appointment: null,
      bookingErrors: [],
      showConfirmUndo: false,
      showConfirmSave: false,
      alertMessageOpen: false,
      alertMessageColor: "danger",
      alertMessageMsg: "danger",
      rejecteds: [],
      calendarViewMode: "month",
      scheduleStatus: "",
      serviceProvider: null,
      confirmOpenNewAppointment: false,
      busy: false,
      services: [],
      openService: false,
      openAddItem:false,
      disabledButton: true,
      customersOptions: [],
      selectedItems:[],
      transfer:{},
      orderSelected:0,
    };
    this.componentCleanup = this.componentCleanup.bind(this);
  }

  componentCleanup() {
    this.confirmUndo();
  }


  setDataToView = (data) => {};

  componentDidMount() {
    this.setCustomersOptions();
    memory.tempService = null;
   
    if (this.props.params.id) {
      
      let appointment = this.formatAppointment(this.props.params.id);
      this.setDataToView(appointment);
    }

    this.getAppointment();
    this.getCancellations();
  }

  fetchItemsDetails = async () => {
    AppointmentApiInvoker.getAppointmentById(
      this.props.params.id,
      items => {
        
        const resultItems = this.formatPracticeModule(items.items);
        this.setState({ items: resultItems });
      }, (error) => {
        console.error('** error getAppointmentItemsDetails: ', error);
      }
    )
  }

  handleSelectItem = (item) => {
    this.setState((prevState) => {
        const selectedItems = [...prevState.selectedItems];
        const index = selectedItems.findIndex((selectedItem) => selectedItem.id === item.id);
        
        if (index > -1) {
            selectedItems.splice(index, 1); 
        } else {
            selectedItems.push({ id: item.id, startDate: item.startDate, endDate: item.endDate });
        }
        
        return { selectedItems };
    }, () => {
        console.log("Selected items:", this.state.selectedItems);
    });
};

  setAppointmentCopy(app) {
    const endDateServ = moment(app.endDate);
    const today = moment().format(dateFormatServer);
    let startDate = moment(endDateServ).add(1, 'days');
    const realOriginEndDate = app.startDate;
    const realOriginStartDate = app.startDate;

    const diffStart = moment().diff(startDate, 'days')
    if (diffStart > 0) startDate = today
    const diffEnd = moment(app.endDate).diff(app.startDate, 'days');
    const endDate = moment(startDate).add(diffEnd, 'days')

    app.startDate = startDate;
    app.endDate = moment(endDate).format(dateFormatServer);
    app.toolsList = []

    this.setState({
      customerId: app.customer.personId,
      provider: {},
      affiliateId: app.financier.financierId,
      description: app.description,
      startDate: '',
      addressId: app.address.addressId,
      creationEmployeeId: app.creationEmployee ? app.creationEmployee.personId : null,
      endDate: '',
      tools: [],
      metadata: app.metadata,
    });
    app.appointmentStatus = 'CREATED';
    this.fetchItems(app, realOriginStartDate, realOriginEndDate);
    this.props.onSelectAppointmentObject(app);
    this.setState({
      loading: false,
      customer: { zone: app.address.geographicZone.geographicZoneId }
    });
  }

  formatPracticeModule = (items) => {
    const { t } = this.props;
    const result = items.map(b => {
      
      return {
        order: b.order,
        practiceTypeLabel:"prueba",
        startDate: `${moment(b.startDate).format(dateFormat)}`,
        quantity: 1,
        serviceProvider:"servicio",
        status:b.transferItemRequestStatus,
        practiceModule:"prueba practica",
        lender:"AXA",
      }
    });
    return result;
  }

  getAppointment = () => {
    const { location, params } = this.props;
    AppointmentApiInvoker.getAppointmentById(params.id, app => {
      if (location.state?.mode === 'copy') {
        this.setAppointmentCopy(app.items);
      } else {
        if (app.status === 'TRANSFER_SCHEDULES_ASSIGNED') {
          this.fetchItemsDetails();
        }
        this.fetchItemsDetails()
        this.props.onSelectAppointmentObject(app);
        this.setState({ metadata: app.metadata, loading: false, customer: { zone: app.address.geographicZone.geographicZoneId } });
        
      }
    }, (error) => {
      console.error('** error getAppointment: ', error);
    });
  }

  getCancellations = () => {
    AppointmentApiInvoker.getTransferAppointmentCancellations(this.props.params.id, cancellations => {
      const cancelledTotal = cancellations.find(c => c.cancelType === 'Total') || {}
      this.setState({
        cancellationReason: cancelledTotal.reason || '',
        cancellations
      });
    }, (error) => {
      console.error('** error getAppointmentCancellations: ', error);
    })
  }


  
  // Función de traducción
  translateStatus = (status) => {
    const statusTranslations = {
      APPROVED: 'Aprobado',
      REJECT: 'Rechazado',
      CANCELLED: 'Cancelado',
      REJECTED: 'Rechazado',
      CREATED: 'Creado'
    };

    return statusTranslations[status] || status;
  };

  formatDate = (date) => {
    let newDate = moment(date);
    if (!newDate.isValid()) {
        console.error("Fecha inválida");
        return null;
    }

    let result = newDate.format('DD/MM/YYYY');
    return result;
  };


  formatAppointment = async (id) => {
    const { t } = this.props;
    try {
      const quantity = await new Promise((resolve, reject) => {
        AppointmentApiInvoker.getQuantity(id, (quantity) => {
          if (quantity) resolve(quantity);
          else reject('Failed to get quantity');
        });
      });
  
      const response = await new Promise((resolve, reject) => {
        AppointmentApiInvoker.getAppointmentById(id, (response) => {
          if (response) resolve(response);
          else reject('Failed to get appointment');
        });
      });
  
      if (response.items) {
        let newServices = [];
        let services = response.items.map((item, index) => ({
           
          order: index + 1,
          id: item.transferItemRequestId,
          type: t(`status.transferWay.${item.transferWay}`) ,
          status: this.translateStatus(item.transferItemRequestStatus),
          driver: item.driver ? (item.driver.lastName + ' ' + item.driver.firstName) : 'Sin asignar',
          serviceName: this.formatTransferTypeRecive(item.transferType ?? "STANDAR"),
          events: this.formatBookings(response.schedules),
          startDate: this.formatDate(item.startDate || response.startDate),
          recurrence: response,
          endDate: this.formatDate(item.endDate || response.endDate),
          quantity: quantity.find(q => q.transferItemRequestId === item.transferItemRequestId).quantity,
          actions:
            (item.transferItemRequestStatus === "CANCELED" || item.transferItemRequestStatus === "ENDED" ||
              this.props.location.state?.mode === 'view') ? (
              <div />
            ) : (
              this.renderActionComponent(item, response,index + 1)
            ),
        }));
  
        if (this.props.location.state?.transferObject) {
          newServices = this.props.location.state.transferObject.items.map((item, index) => ({
            order: services.length + index + 1,
            id: item.transferItemRequestId,
            driver: item.driver ? (item.driver.lastName + ' ' + item.driver.firstName) : 'Sin asignar',
            status: this.translateStatus(item.transferItemRequestStatus),
            type: "Traslado",
            serviceName: this.formatTransferTypeRecive(item.transferType ?? "STANDAR"),
            events: this.formatBookings(response.schedules),
            startDate: this.formatDate(item.startDate || response.startDate),
            endDate: this.formatDate(item.endDate || response.endDate),
            recurrence: response,
            quantity:quantity.find(q => q.transferItemRequestId === item.transferItemRequestId).quantity,
          }));
          services = [...services, ...newServices];
        }
  
        this.setState({
          loading: false,
          services: services,
          userCreator: `${response.createEmployeeFirstName} ${response.createEmployeeLastName}`,
        });
  
        const customer = await new Promise((resolve, reject) => {
          PersonApiInvoker.getCustomer(response.customerId, (customer) => {
            if (customer) resolve(customer);
            else reject('Failed to get customer');
          });
        });
  
        const address = await new Promise((resolve, reject) => {
          PersonApiInvoker.getPersonsAddressMobility(response.customerId, (address) => {
            if (address) resolve(address);
            else reject('Failed to get address mobility');
          });
        });
  
        this.setState({
          loading: false,
          customerId: customer.id,
          appointment: {
            customer: {
              customer: customer.personId,
              name: customer.firstName,
              lastname: customer.lastName,
              typeDocument: customer.idType.name,
              identity: customer.id,
              address: address[0].addressId,
              zone: address[0].geographicZone.detail,
              location: address[0].location.locationId,
              province: address[0].provinceName,
              address_name: address[0].street,
              latitude: address[0].latitude,
              longitude: address[0].longitude,
            },
            startDate: response.startDate,
            endDate: response.endDate
          },
        });
  
        const financiers = await new Promise((resolve, reject) => {
          BusinessApiInvoker.getCustomersFinanciers(customer.personId, (financier) => {
            if (financier) resolve(financier);
            else reject('Failed to get financiers');
          });
        });
  
        this.setState((prevState) => ({
          loading: false,
          appointment: {
            ...prevState.appointment,
            financier: {
              financierId: financiers[0].financier.financierId,
              affiliateId: financiers[0].affiliateId,
              financiers: financiers,
            },
            provider: {
              dateStart: response.startDate,
              dateEnd: response.endDate,
            },
            appointmentId: response.customerId,
            appointmentStatus: response.status,
            schedules: response.schedules,
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching appointment details:", error);
      this.setState({ loading: false });
    }
  };

  setCustomersOptions = () => {
    PersonApiInvoker.getCustomersMobility((response) => {});
  };

  componentWillMount() {
    this.setCustomersOptions();
    window.addEventListener("unload", this.componentCleanup);
  }

  getServiceProviderOnEdit = stateItems => {
    let sprov = this.state.cancellations && this.state.cancellations.length > 0 ? this.state.cancellations[0].serviceProvider : {};
    sprov = this.state.serviceProvider ? this.state.serviceProvider : sprov;
    const itemsExist = stateItems && stateItems.length > 0;
    return itemsExist ? stateItems[0].serviceProvider : sprov; 
  }

  getProvider = () => {
    const { customer, startDate, endDate } = this.state.appointment;
    return {
      loading: false,
      customerId: {
        customer: customer?.customer,
      },
      financier:customer?.financierId,
      provider: this.getServiceProviderOnEdit(this.state.items),
      dateStart: startDate,
      dateEnd: endDate,
    };
  };

  onBack = () => {
    browserHistory.push(`/solicitudes-traslado`);
  };

  setProviderF(value) {
    this.setState(
      {
        provider: value,
      },
      () => {}
    );
  }

  setFinancier(value) {
    this.setState({
      financierData: value,
      financierId: value.financierId ? value.financierId : "",
      affiliateId: value.affiliateId ? value.affiliateId : "",
    });
  }

  getPlanQuantity(plan, startDate, endDatePlan, endDateAppointment, makeEvent) {
    const events = [];
    if (startDate == "") {
      return 0;
    }
    let endDate = endDateAppointment;
    if (!(endDate instanceof Date)) {
      endDate = moment(endDate, "YYYY-MM-DD")
    }
    if (endDatePlan != null) {
      endDate = endDatePlan
    }

    let quantity = 1;
    const every = plan.recurrence.every;

    if (
      plan.recurrence.endType === "OCURRENCES" &&
      (plan.recurrence.finishOcurrences || plan.recurrence.ocurrences)
    ) {
      quantity = plan.recurrence.finishOcurrences || plan.recurrence.ocurrences;
    } else {
      if (moment(startDate).diff(endDate, "days") > 0) {
        return 0
      }

      let currentDate = startDate;
      const endDatePlusOne = moment(endDate, "YYYY-MM-DD").add(1, "d");
      let q = 0;
      let e = every

      if (plan.recurrence.recurrenceType === "DAY") {
        while (!moment(currentDate).isSame(endDatePlusOne, "day") && q < 100) {
          if (e == every) {
            q++;
            if (makeEvent) this.makeEvent(events, currentDate, q);

            e = 1;
          } else {
            e++;
          }
          currentDate = moment(currentDate).add(1, "days")
        }
        quantity = q;
      } else if (
        plan.recurrence.recurrenceType === "WEEK" ||
        plan.recurrence.recurrenceType === "INTRADAY"
      ) {
        let weekDays =
        plan.recurrence.weekDays || plan.recurrence.weekRecurrence;
        if (weekDays == null) {
          weekDays = "";
        }
        weekDays =
          typeof weekDays === "string" ? weekDays.split(",") : weekDays;
        let weekDaysSchedulePlan = [];
        if (weekDays.includes("L")) {
          weekDaysSchedulePlan.push(1);
        }
        if (weekDays.includes("X")) {
          weekDaysSchedulePlan.push(3);
        }
        if (weekDays.includes("M")) {
          weekDaysSchedulePlan.push(2);
        }
        if (weekDays.includes("J")) {
          weekDaysSchedulePlan.push(4);
        }
        if (weekDays.includes("V")) {
          weekDaysSchedulePlan.push(5)
        }
        if (weekDays.includes("S")) {
          weekDaysSchedulePlan.push(6)
        }
        if (weekDays.includes("D")) {
          weekDaysSchedulePlan.push(0)
        }
        let yesterdary;
        while (!moment(currentDate).isSame(endDatePlusOne, "day")) {
          if (e == every) {
            if (weekDaysSchedulePlan.includes(currentDate.day())) {
              if (plan.recurrence.recurrenceType === "INTRADAY") {
                q =
                  q +
                  parseInt(
                    plan.recurrence.quantityIntraday
                      ? plan.recurrence.quantityIntraday : 1
                  );
              } else {
                q++;
                if (makeEvent) this.makeEvent(events, currentDate, q);
              }
            }
          }
          yesterdary = currentDate
          currentDate = moment(currentDate).add(1, "days");
          if (plan.recurrence.recurrenceType === "WEEK") {
            if (!moment(yesterdary).isSame(currentDate, "week")) {
              if (e == every) {
                e = 1;
              } else {
                e++;
              }
            }
          }
        }
        quantity = q;
      } else if (plan.recurrence.recurrenceType === "MONTH") {
        let yesterdary;
        let monthDay = plan.recurrence.monthRecurrence || plan.recurrence.monthDay;

        while (!moment(currentDate).isSame(endDatePlusOne, "day")) {
          if (e == every) {
            if (currentDate.date() == monthDay) {
              q++;
              if (makeEvent) this.makeEvent(events, currentDate, q)
            }
          }
          yesterdary = currentDate;
          currentDate = moment(currentDate).add(1, "days");
          if (!moment(yesterdary).isSame(currentDate, "month")) {
            if (e == every) { e = 1 } 
            else {
              e++;
            }
          }
        }
        quantity = q
      }
    }
    if (makeEvent) {
      return events;
    }
    return quantity;
  }

  openAddService = (a) => {
    this.setState({ 
      openService: true, 
      itemSelect: a.transferItemRequestId });
  };
  
  openAddItem = ()=>{
    this.setState({ 
      openAddItem: true 
    });
  }

 

  constructCustomerFormValue = () => {
    const { appointment } = this.state;
    if (appointment && appointment.customer) {
      return {
        customer: appointment.customer.customer || null,
        address: appointment.customer.address || null,
        zone: appointment.customer.zone || null,
        location: appointment.customer.location || null,
        province: appointment.customer.province || null,
        address_name: appointment.customer.address_name || null,
      };
    }
    return null;
  };

  renderActionComponent = (a, appointment, order) => {
    const { t } = this.props;
    
    if (appointment && appointment.appointmentStatus === "CREATED" && this.props.location.state?.mode !== "edit_agenda" ) {
      return (
        <div className="appointments-actions">
          <Tooltip title={t("appointment.service.edit")}>
            <div>
              <Button
                simple
                justIcon
                color="info"
                onClick={() => this.openAddService()}
              >
                <Assignment />
              </Button>
            </div>
          </Tooltip>
        </div>
      );
    } else  if (this.props.location.state?.mode !== "edit_agenda" && a.transferItemRequestStatus !== "REJECTED" && a.transferItemRequestStatus !== "ENDED"){
      return (
        <div className="appointments-actions">
          <Tooltip title={t("appointment.service.edit")}>
            <div>
              <Button
                simple
                justIcon
                color="info"
                onClick={() => this.openAddService(a)}
              >
                <Edit />
              </Button>
            </div>
          </Tooltip>
          {
            a.transferItemRequestStatus === "APPROVED" || a.transferItemRequestStatus === "TRANSFER_SCHEDULES_ASSIGNED"  ?
            <></>:
            <Tooltip title={t("appointment.service.delete")}>
            <div>
              <Button
                simple
                color="danger"
                justIcon
                authority="appointment_view,appointment_view_related"
                onClick={() => this.openCancelAppointment(a.transferItemRequestId, order)}
              >
                <Delete />
              </Button>
            </div>
          </Tooltip>
          }
        </div>
      );
    }else{
      return(<div/>)
      
    }
  };

  closeService = () => {

      this.setState((prevState) => ({
        ...prevState,
        openService: false,
      }));

      this.setState((prev)=>({
        ...prev,
        type:'',
      }))
      this.setState((prev)=>({
        ...prev,
        openAddItem:false,
      }))
        
  };


  makeEvent = (events, date, index) => {
    const time = memory.tempService.startTime;
    date = new Date(date).setHours(
      parseInt(time.substring(0, 2)),
      parseInt(time.substring(3, 5)),
      0
    )
    const label = memory.tempService.traslado.value;
    events.push(this.makeBooking(label, date, time, index))
};

  makeBooking = (label, date, time, lastIndexOf) => {
    return {
      id: lastIndexOf,
      title: label,
      label,
      view: "month",
      start: date,
      time_display: time,
      time_start: time,
      allDay: false,
      end: date,
      color: "F06292",
      employeeId: -1,
      status: "PENDING_APPROVAL",
      errorDetail: "",
      type: "booking",
    };
  };


  asignSchedules = () => {
    let items = this.state.selectedItems.map(item => item.id);
    let self = this; // Referencia a `this` para usar dentro del callback
  
    AppointmentApiInvoker.newScheduleWithItems(
      this.props.params.id,
      items,
      (response) => {
        self.response = response;
  
        self.redirectToTurnosTraslado();
      },
      (error) => {
        console.error("Error en la llamada al API:", error);
      }
    );
  };
  
  redirectToTurnosTraslado = (bookings) => {
  
    browserHistory.push({
      state: {
        transferId:this.props.params.id,
        appointment: this.state?.appointment ?? "",
        bookings: this.response?.schedules ?? [],
        fromAppointmentAssignAgenda:true,
        dates: {
          startDate: this.state?.appointment?.provider?.dateStart ?? "",
          endDate: this.state?.appointment?.provider?.dateEnd ?? "",
        },
      },
      pathname: `/turnos-traslado/${this.props.params.id}`,
    });
  };
  
  
  

  formatTransferTypeRecive = (type) => {
    if (type === "STANDAR") {
      return "Traslado estándar";
    } else if (type === "RAMPA") {
      return "Traslado con rampa";
    } else if (type === "AMBULANCIA") {
      return "Traslado con ambulancia";
    }
  };


  selectedEvent(event, type) {
    this.setState({ event: event, openService: true, type:type });
  }

  eventColors(event, start, end, isSelected) {
    let backgroundColor = "event-";
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default");
    return {
      className: backgroundColor,
    };
  }


  setTranferType = (type) => {
    if (type === "STANDAR") { return "Traslado estándar" } 
    else if (type === "RAMPA") { return "Traslado con rampa" } 
    else if (type === "AMBULANCIA") { return "Traslado con ambulancia" }
  };

  getColor(group) {
    const colors = {
      0: "E157CA",
      1: "F06292",
      2: "5AB4FF",
      3: "79009B",
      4: "D81B60",
      5: "0A85FF",
      6: "BF00B0",
      7: "880E4F",
      8: "1F3FB9"
    };

    return colors[group % 9];
  }

  formatBookings(data) {
    let bookings = data;
    // eslint-disable-next-line no-undef
    let result = [];
    bookings = _.orderBy(bookings, ["scheduleDateTime"], ["asc"]);
    bookings.map((b) => {
      if (b) {
        result.push({
          id: b.transferScheduleId,
          time_display: "",
          title: `${this.setTranferType(b.transferItemRequestId.transferType)}`,
          time_start: moment(b.startDateTime).format("HH:mm"),
          allDay: false,
          end: b.endDateTime,
          color: this.getColor(1),
          employeeId: b.employeeId,
          view: this.state.calendarViewMode,
          start: b.startDateTime,
          status: b.transferScheduleStatus,
          type: b.type,
          errorDetail: "",
          origin: b,
        });
      }
    });
    this.setState({ events: result });
  }

  setStatusAppointment = (status) => {
    if (status === "TRANSFER_SCHEDULES_ASSIGNED") {
      return "Asignada";
    } else if (status === "CREATED") {
      return "Creada";
    } else if (status === "CANCELLED") {
      return "Cancelada";
    }
    
  };

  cancelAppointmentAction = () => {
    
    AppointmentApiInvoker.deleteTransferItem(this.state.selectedCancelAppointment,  () => {
      this.setState({ showConfirmCancelAppointment: false , showConfirmationCancelAppointment: true })
    }, () => {
      this.setState({ showConfirmationCancelAppointment: true, showConfirmCancelAppointment: false })
    });
  }

  cancellationsTableData = (data, t) => data.map(cancelation => {
    return {
      'order': cancelation.order,
      'type': t(cancelation.type),
      'count': cancelation.count,
      'speciality': cancelation.speciality,
      'startDate': moment(cancelation.startDate).format(dateFormat),
      'cancelDate': moment(cancelation.cancelDate).format(dateFormat),
      'reason': cancelation.cancelType === 'Total' ? '' : cancelation.reason,
    }
  });
  getColorIcon(isEvents, isCancellations) {
    switch (true) {
      case (isEvents) && (isCancellations):
      case (!(isEvents) && !(isCancellations)):
        return 'secondary';
    
      default:
        return 'primary';
    }
  }


  continueService = ()=>{
    
    browserHistory.push({
      pathname: "/detalle-servicio",
      state: { 
        appointment: this.props.appointments.selectedAppointment,
        transfer: this.state.transfer,
        origin:this.props.params.id
      }
  });
      
  }

  getProviderValue(selectedAppointment) {
    return selectedAppointment ? this.getProvider() : null;
  }

  setProvider = (serviceProvider) => this.setState({ 'serviceProvider': serviceProvider });


  openCancelAppointment = (appointmentId, order) => {
    this.setState({ selectedCancelAppointment: appointmentId, orderSelected:order },
      () => {
        this.setState({ showConfirmCancelAppointment: true });
      }
    );
  }

  perfomSuccessReload = () => {
    this.setState((prevState) => ({
      showConfirmationCancelAppointment: false,
      showConfirmationEditAppointment: false
    }), () => {
      this.formatAppointment(this.props.params.id)
    });
  }
 

  setData = (validation) => {
    if (validation) {
      this.setState({ disabledButton: false });
    }
  };
  handleChangeAdd = (value)=>{
    this.setState({
      transfer:{
      type:value,
      startDate:this.props?.appointments?.selectedAppointment?.startDate,
      endDate:this.props?.appointments?.selectedAppointment?.endDate,
    }
    
    }
    )
    this.setState(prevState => ({
      transferAppointment: {
        ...prevState.transferAppointment,
        transferWay: value,
      }
    }));
  }

  actionEdit = ()=>{
    this.setState({showConfirmationEditAppointment:true})
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {}

  render() {
    const { t } = this.props;
    
    const { appointment, customerId, event, openService,openAddItem,itemSelect, provider, services, showConfirmCancelAppointment,
       selectedCancelAppointment,showConfirmationCancelAppointment, showConfirmationEditAppointment, orderSelected } =
      this.state;
      if (!appointment && !appointment?.appointmentId) {
        return (<FullLoading />)
      }
    return (
      <GridContainer>
        {appointment && (
          <GridItem className="appointment-data" xs={12}>
            <Card>
              <CardHeader icon>
                <CardIcon color="secondary">
                  <Assignment />
                </CardIcon>
                <h3 className="card-icon-title">{t("appointment.title")}</h3>
              </CardHeader>
              <CardBody>
                <GridContainer className="appointment-detail-schedule">
                  <GridItem
                    className="fullWidth-input validation-input"
                    md={4}
                    xs={4}
                    sm={4}
                  >
                    <CustomInput
                      id="appointment_id"
                      disabled={true}
                      labelText={t("appointment.input.appointment_id")}
                      value={
                        appointment.appointmentId
                          ? appointment.appointmentId
                          : ""
                      }
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem
                    className="fullWidth-input validation-input center-align"
                    md={4}
                    xs={4}
                    sm={4}
                  >
                    <CustomInput
                      id="appointment_status"
                      labelText={t("appointment.input.status")}
                      value={
                        appointment.appointmentStatus
                          ? this.setStatusAppointment(
                              appointment.appointmentStatus
                            )
                          : ""
                      }
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem className="align-right" md={4} xs={4} sm={4}>
                    <div className="align-right">
                      <CustomInput
                        id="init_date"
                        disabled={true}
                        labelProps={{ style: { marginTop: "-10px" } }}
                        inputProps={{
                          style: {
                            textAlign: "right",
                            width: "100%",
                            marginLeft: "-20px",
                          },
                        }}
                        labelText={t("appointment.input.init_date")}
                        value={
                          appointment
                            ? moment(appointment.createdDateTime).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )
                            : null
                        }
                        formControlProps={{
                          disabled: true,
                        }}
                      />
                    </div>
                  </GridItem>
                </GridContainer>
                <GridContainer className="appointment-detail-schedule">
                  <GridItem
                    className="fullWidth-input validation-input"
                    xs={4}
                    sm={4}
                  >
                    <ValidationInput
                      id="appointment_id"
                      classes={{
                        input: "fullWidth-input",
                      }}
                      fullWidth
                      text={t("appointment.input.created_user")}
                      disabled={true}
                      className="fullWidth-input"
                      value={this.state.userCreator}
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem
                    className="fullWidth-input validation-input center-align"
                    xs={4}
                    sm={4}
                  >
                    <CustomInput
                      id="clientId"
                      labelText={t("appointment.input.clientId")}
                      value={"HMM"}
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                  <GridItem
                    className="fullWidth-input align-right"
                    xs={4}
                    sm={4}
                  >
                    <CustomInput
                      id="foreignId"
                      labelText={t("appointment.input.foreignId")}
                      value={"-"}
                      labelProps={{
                        style: { textAlign: "right", width: "100%" },
                      }}
                      inputProps={{
                        style: { textAlign: "right", width: "75%" },
                      }}
                      formControlProps={{
                        disabled: true,
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        )}
        <GridItem xs={6} sm={6} md={6}>
          <SchedulerService.CustomerFormService
            setData={this.setData}
            onFilledCustomer={(value) => {
              this.setState({
                customerId: value,
                addressId: value.address,
                zoneId: value.zone,
              });
            }}
            value={this.constructCustomerFormValue()}

            onUpdateCustomers={this.onUpdateCustomers}
            disabled={appointment != null}
          />
        </GridItem>

        <GridItem xs={6} sm={6} md={6}>
          <SchedulerService.FinancialFormService
            serviceType={"service"}
            customerId={customerId}
            onFilledFinancier={(value) => this.setFinancier(value)}
            onFilledProvider={(value) => this.setProviderF(value)}
            value={
              this.state.appointment
                ? {
                    provider: appointment.provider,
                    financier: appointment.financier,
                    dateStart: appointment.startDate,
                    dateEnd: appointment.endDate,
                    customerId: appointment.customer,
                  }
                : null
            }
            disabled={appointment != null}
          />
        </GridItem>

        <GridItem className="schedule-form" xs={12} sm={12} md={12}>
          <Card>
            <CardHeader icon className="filters-header">
              <CardIcon color="secondary">
                <ViewList />
              </CardIcon>
              <h3 className="card-icon-title">{t("appointment.services")}</h3>
            </CardHeader>
            <CardBody className="appointments-content-body filtrable-table">
              <Table
                filterable
                tableHeaderColor={"primary"}
                loading={false}
                defaultPageSize={services.length > 10 ? 20 : 10}
                sortable
                tableHead={[
                  {
                    Header: "",
                    accessor: "selector",
                    Cell: ({ original }) => {
                      return (
                      this.props.location.state?.mode === "assign_agenda"  && original.status === "Aprobado" ? (
                        <input
                          type="checkbox"
                          checked={this.state.selectedItems.some(item => item.id === original.id)}
                          onChange={() => this.handleSelectItem(original)}
                        />
                      ) : null)
                  },
                    sortable: false,
                    filterable: false,
                  },
                  { Header: t("service.order"), accessor: "order" },
                  { Header: t("transfer.type"), accessor: "type" },
                  { Header: "Estado", accessor: "status" },
                  { Header: t("service.name"), accessor: "serviceName" },
                  { Header: t("service.driver"), accessor: "driver" },
                  {
                    Header: t("service.start_date"),
                    accessor: "startDate",
                    sortMethod: (a, b) => this.sortDate(a, b),
                  },
                  {
                    Header: t("service.quantity"),
                    accessor: "quantity",
                    sortMethod: (a, b) => this.sortDate(a, b)
                  },
                  {
                    Header: t("appointment.table.actions"),
                    accessor: "actions",
                    sortable: false,
                    filterable: false
                  }
                ]}
                colorsColls={["primary"]}
                tableData={services}
                className="-striped -highlight filtrable sticky"
                showPaginationTop={false}
                showPaginationBottom={true}
              />
            </CardBody>
            
            <CardFooter className="schedule-form-submit buttonFooter">
              {!memory.tempService && (
                <Button
                  className="buttonPlusFooter"
                  onClick={() => this.openAddItem()}
                  disabled={this.props.location.state.mode === "view" ? true: false}
                  color="primary"
                >
                  <Add />
                </Button>
              )}
            </CardFooter>
          </Card>

          <CustomDialog
          maxWidth={"xs"}
          title="Seleccionar tipo de traslado"
          open={openAddItem}
          onClose={() => this.closeService()}
        >
            <div>
              <GridContainer
                className={"ps-child modules-form"}
              >
                <GridItem xs={12} sm={12} md={12} className="componentType">
                  <SelectInput
                    label="Tipo"
                    elements={this.state.types}
                    onSelectedValue={(value) =>
                      this.handleChangeAdd(value)
                    }
                    value={this.state.type}
                  />
                </GridItem>
              </GridContainer>
            </div>
            <CardFooter className="schedule-form-submit buttonFooter">
              <Button
                className="buttonPlusFooter"
                onClick={() => this.continueService()}
                color="primary"
                disabled={false}
              >
                Continuar
              </Button>
            </CardFooter>
        </CustomDialog>

          

          {
            appointment?.schedules &&
            (appointment?.appointmentStatus === "TRANSFER_SCHEDULES_ASSIGNED" ||  appointment?.appointmentStatus === "ENDED") && (
              <Card>
                <CardHeader icon className="filters-header">
                  <CardIcon color="secondary">
                    <CalendarToday />
                  </CardIcon>
                  <h3 className="card-icon-title">{t("appointment.agenda")}</h3>
                </CardHeader>
                <CardBody className="calendar-booking" calendar>
                  <Calendar
                    selectable
                    events={this.state.events}
                    defaultView="month"
                    showMultiDayTimes={true}
                    localizer={localizer}
                    step={30}
                    views={["month"]}
                    scrollToTime={new Date(1970, 1, 1, 6)}
                    defaultDate={new Date()}
                    onSelectEvent={(event) => this.selectedEvent(event,"calendar")}
                    eventPropGetter={this.eventColors}
                    messages={{
                      next: t("common.next"),
                      previous: t("common.prev"),
                      today: t("common.today"),
                      month: t("common.month"),
                      week: t("common.week"),
                      day: t("common.day"),
                      event: t("common.event"),
                      date: t("common.date"),
                      time: t("common.hour")
                    }}
                    components={{
                      event: EventWithIcon,
                    }}
                  />
                </CardBody>
              </Card>
            )}

          <GridContainer className="buttons-containers">
            <GridItem xs={10} sm={10} md={10}>
              <Button onClick={() => this.onBack()}> {t("appointment.cancel")} </Button>
            </GridItem>
            <GridItem xs={2} sm={2} md={2} style={{ textAlign: "right" }}>
              {appointment != null &&
                ((appointment.appointmentStatus === "CREATED" &&
                this.props.location?.state?.mode !=="view") || this.props.location?.state?.mode ==="edit_agenda")  && (
                  <Button
                    color="blue"
                    className="align-right"
                    onClick={() => this.asignSchedules()}
                    disabled={this.state.selectedItems.length > 0 ? false : true}
                  >
                    {t("appointment.asign")}
                  </Button>
                )}
              
            </GridItem>
          </GridContainer>
        </GridItem>
        <ModalDetailServiceTraslado
          open={openService}
          type={this.state.type}
          onCloseModal={this.closeService}
          disabled={appointment}
          event={event}
          viewMode={this.props.location.state?.mode}
          customer={appointment ? appointment.customer : customerId}
          onSaveService={this.addService}
          editData={services}
          provider={provider}
          appointment={appointment}
          itemSelect={itemSelect}
          actionEdit={this.actionEdit}
        />
        
        {showConfirmCancelAppointment &&
          <CustomSweetAlert
            type="warning"
            onConfirm={this.cancelAppointmentAction}
            cancelBtnCssClass="danger"
            onCancel={() => this.setState({ showConfirmCancelAppointment: false })}
            confirmBtnCssClass="primary"
            title={t('appointment.created.cancel.title')}
            cancelBtnText={t('appointment.created.cancel.cancel')}
            confirmBtnText={t('appointment.created.cancel.confirm')}
            showCancel={true}
            message={<p>{t('appointment.created.cancel.message')} {orderSelected}</p>}
          />
        }
        {showConfirmationCancelAppointment &&
          <CustomSweetAlert
            type="success"
            onConfirm={this.perfomSuccessReload}
            confirmBtnText={t('appointment.created.cancel.confirmation.OK')}
            showCancel={false}
            confirmBtnCssClass="primary"
            title={t('appointment.created.cancel.confirmation.title')}
            message={<p>{selectedCancelAppointment} {t('appointment.created.cancel.confirmation.message')}</p>}
          />
        }
        {showConfirmationEditAppointment &&
          <CustomSweetAlert
            type="success"
            onConfirm={this.perfomSuccessReload}
            title={t('appointment.created.edit.confirmation.title')}
            confirmBtnText={t('appointment.created.edit.confirmation.OK')}
            showCancel={false}
            confirmBtnCssClass="primary"
            message={<p>{t('appointment.edit.confirmation.message')}</p>}
          />
        }
      </GridContainer>
    );
  }
}

AppointmentDetail.defaultProps = {
  selectedSchedules: [],
  onSaveSchedulesAppointment: () => {},
};

AppointmentDetail.propTypes = {
  t: PropTypes.func,
  appointments: PropTypes.object,
  selectedAppointment: PropTypes.object,
  selectedSchedules: PropTypes.array,
  onSelectAppointmentObject: PropTypes.func,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const styles = {
  cardHeader: {
    zIndex: "0 !important",
  },
};

export default withStyles(styles)(withTranslation()(AppointmentDetail));
