/* eslint-disable react-hooks/exhaustive-deps */
import './vehicle.css'
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { browserHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Add, Edit, Person } from '@material-ui/icons';
import ApiInvoker from '../../api/ApiInvoker';
import Button from '../../components/CustomButtons/Button';
import Card from '../../components/Card/Card';
import CardBody from '../../components/Card/CardBody';
import CardHeader from '../../components/Card/CardHeader';
import CardIcon from '../../components/Card/CardIcon';
import FullLoading from '../../components/FullLoading';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';
import Snackbar from '../../components/Snackbar/Snackbar';
import Table from '../../components/Table/Table';
import { Assignment,Drivers as DriversIcon, TextSnippet } from '../../icons';
import { useVehicleData } from '../../hooks/useVehicleData';
import { Tooltip } from '@material-ui/core';
import { CardFooter } from '../Card';
import CustomDialog from '../CustomDialog';
import VehicleInfo from './VehicleInfo';
import Trash from '../../icons/Trash';
import DriverApiInvoker from '../../api/DriverApiInvoker';
import SelectInput from '../SelectInput';
import CustomSweetAlert from '../CustomSweetAlert';
import UpdateDownImage from './UpDownImage';

const showSnackbarInitialValue = {
  open: false,
  color: 'danger',
  message: ''
}

const formSettingsInitialValue = {
  mode: 'view',
  save: false,
}

const Driver = ({ location, params, t }) => {

  const {  brands, models, types, wheelChairSuitable } = useVehicleData(t);

  const [formSettings, setFormSettings] = useState(formSettingsInitialValue);
  const [driver, setDriver] = useState({});
  const [drivers, setDrivers] = useState([]);
  const [documentsType, setDocumentsType] = useState({});
  const [dataVehicle, setDataVehicle] = useState({})
  const [driverSelected , setDriverSelected]= useState();
  const [vehicle, setVehicle] = useState({vehiclePatent: '',});
  const [driversVehicle, setDriversVehicle] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [documentSelected, setDocumentSelected] = useState([]);
  const [documentSelectedEdit, setDocumentSelectedEdit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mode, setMode]= useState(location.state.mode);
  const [personId, setPersonId] = useState('')

  const [openDriver, setOpenDriver] = useState(false)
  const [openDocuments, setOpenDocuments] = useState(false)
  const [phone, setPhone] = useState(null)

  const [showSnackbar, setShowSnackbar] = useState(showSnackbarInitialValue);
  const [showConfirmationAddDriver,setShowConfirmationAddDriver] = useState(false);
  const [showConfirmationRemoveDriver,setShowConfirmationRemoveDriver] = useState(false);
  const [inputActive, setInputActive] = useState(true)

  const submitDriver = () => {
      saveDriver();
  }

  useEffect(() => {
   
    const timeoutId = setTimeout(() => {
      setPersonId(params.id);
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, []);

  const saveDriver = () => {

    const formattedDriver = {
      ...dataVehicle,
      carType:dataVehicle.carType,
      vehicleBrand:{
          vehicleBrandId:dataVehicle.vehicleBrand,
          name:""
      },
      vehicleModel:{
          vehicleModelId:dataVehicle.vehicleModel,
          name:""
      },
      wheelChairSuitable: dataVehicle.vehicleSuitableForChairs,
      year:dataVehicle.year,
      patent:dataVehicle.vehiclePatent,
      fit:true,
      identity:"",
      };
    
    
    DriverApiInvoker.createVehicle(formattedDriver, (response) => {
      openSnackbar(t('common.successMessage.vehicle.save'), 'success');
      setDataVehicle(response)

     let body ={
      companyPartnership: JSON.parse(localStorage.getItem('company'))?.company_id || null,
      vehicleId: response.vehicleId,
      active: true
      }
      
      DriverApiInvoker.vehicleSaveCompany(body, (data) => {

      }, (error) => {
        openSnackbar(t('common.errorMessage'));
        console.error('** error', error);
      });
      
    

      
    }, (error) => {
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    });
    
  }

  
  const openSnackbar = (message, color = 'danger') => {
    setShowSnackbar({
      color,
      message,
      open: true,
    });

    setTimeout(() => {
      setShowSnackbar(showSnackbarInitialValue);
    }, 2000);
  }

  const formatVehicle = (data) => ({
    vehicleId: data.vehicleId,
    vehicleModel: data.vehicleModel.vehicleModelId,
    vehicleBrand: data.vehicleBrand.vehicleBrandId,
    carType: data.carType,
    year: data.year,
    vehiclePatent: data.patent,
    wheelChairSuitable: data.wheelChairSuitable,
    drivers: [{}],
   
  })

  const formatDataVehicle = (data) => ({
    vehicleId: data.vehicleId,
    carType: data.carType,
    vehicleBrand: data.vehicleBrand.vehicleBrand,
    vehicleModel: data.vehicleModel.vehicleModel,
    wheelChairSuitable: data.wheelChairSuitable,
    year: data.year,
    patent: data.patent,
    fit: true,
    identity: data.patent,
    verificationFile: data.verificationFile || null,
    titleFile: data.titleFile || null,
    secureFile: data.secureFile || null,
    targetFile: data.targetFile || null
  })

  const getDriver = () => {
    setLoading(true);
    DriverApiInvoker.getDocuments(params.id, (data) => {
      
      const d = formatVehicle(data);
      const dataVehicle = formatDataVehicle(data)
      
      setVehicle(d);
      setDataVehicle(dataVehicle)
      setLoading(false);
      return;
    }, (error) => {
      setLoading(false);
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }

  const getAssignedDriver = ()=>{
    DriverApiInvoker.getAssignedDriver(location.state.mode === "create" ? dataVehicle.vehicleId : params.id, (data) => {
      
      const d = formatAssignedDrivers(data);
    
      setDriversVehicle(d);
      setLoading(false);
      return;
    }, (error) => {
      setLoading(false);
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }

  const getDrivers = () => {
    setLoading(true);
    ApiInvoker.getDrivers(0, (data) => {
      
      const d = formatDrivers(data);
      
      setDrivers(d);
      setLoading(false);
      return;
    }, (error) => {
      setLoading(false);
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }
  
  const formatDrivers = (data) => data.map((d) =>({
    id: d.personId,
    value: `${d.lastName} ${d.firstName}`,
    
  }))
 

  const formatVehicles = (data) => data.map((d) =>({
    brand: d.brand,
    model: d.model,
    type: d.carType ? t(`label.cardType.${d.carType}`) : t('label.undefined'),
    wheelChairSuitable: d.wheelChairSuitable ? t('common.yes') : t('common.no'),
    year: d.year,
    patent: d.patent,
    id: d.vehicleId,
  }))
  
  const formatAssignedDrivers = (data) => data.map((d) =>({
    lastName: d.driver.lastName,
    firstName: d.driver.firstName,
    identification: d.driver.id,
    email: d.driver.email,
    vehicle: `${d.vehicle.brand} ${d.vehicle.model}`,
    geograficZone: 'No',
    idDriver: d.driverVehicleId,
  }))

  const getDriverVehicles = () => {
    ApiInvoker.getDriverVehicles(params.id, (data) => {
      const v = formatVehicles(data);
      //setDriversVehicle(v)
      return;
    }, (error) => {
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }

  const getDocumentsVehicle = () => {
    DriverApiInvoker.getDocuments(params.id, (data) => {
      return;
    }, (error) => {
      openSnackbar(t('common.errorMessage'));
      console.error('** error', error);
    })
  }

  const getDriverDocuments = ()=>{
    
      const documents = [
        { type: 'Verificación técnica', name:'verificationFile', active:dataVehicle.verificationFile , url:dataVehicle.verificationFile },
        { type: 'Título de vehículo', name:'titleFile', active:dataVehicle.titleFile , url:dataVehicle.titleFile || '' },
        { type: 'Seguro de vehículo',  name:'secureFile',active:dataVehicle.secureFile , url:dataVehicle.secureFile || '' },
        { type: 'Tarjeta azul',  name:'targetFile',active:dataVehicle.targetFile ,  url:dataVehicle.targetFile || '' },     
      ]
    
    const activeDocuments = documents.filter(doc => doc.active);
  
    setDocuments(activeDocuments);
  }
  const getDocumentsType = () => {
    const documents = [
      { id: 'verificationFile', value: 'Verificación técnica', active: dataVehicle.verificationFile },
      { id: 'titleFile', value: 'Título de vehículo', active: dataVehicle.titleFile },
      { id: 'secureFile', value: 'Seguro de vehículo', active: dataVehicle.secureFile },
      { id: 'targetFile', value: 'Tarjeta azul', active: dataVehicle.targetFile },
    ];
  
    const activeDocuments = documents.filter(doc => !doc.active);
  
    setDocumentsType(activeDocuments);
  };
  const loadData = () => {
    getDrivers();
    setFormSettings((prev) => ({ prev, mode: location && location.state && location.state.mode}));
    if(location.state.mode !== "create"){
      getDriver();
      getDriverVehicles();
      getAssignedDriver()
      getDocumentsVehicle();
      getDriverDocuments();
      getDocumentsType()
    }
    
  }

  useEffect(() => {
    loadData();
    if (location.state && location.state.mode) {
      setFormSettings(prev => ({ ...prev, mode: location.state.mode }));
    }
    return () => {
      setLoading(false);
      setDriver({});
      setDrivers([])
      setVehicle([]);
      setLoading([]);
      setPersonId(params.id)
    }
  }, []);

  useEffect(() => {
    getDriverDocuments()
    getDocumentsType()
  }, [dataVehicle])
  

  const handleDriverValue = (state, value) => {
    setDataVehicle((prev) => ({ ...prev, [state]: value }));
    setVehicle((prev) => ({ ...prev, [state]: value }))

    if(state ==="vehiclePatent"){
      setDataVehicle((prev) => ({ ...prev, identity: value, patent: value }));
      setVehicle((prev) => ({ ...prev, identity: value, patent: value }))
    }
    if(state ==="vehicleBrand" && location.state.mode !== "create" ){
      setDataVehicle((prev) => ({ ...prev, vehicleBrand: {vehicleBrandId:value} }));
      
    }
    if(state ==="vehicleModel" && location.state.mode !== "create"){
      setDataVehicle((prev) => ({ ...prev, vehicleModel: {vehicleModelId:value}}));
      
    }
    
  }

  const goBack = () => browserHistory.push('/vehicles');

  const addDriver = ()=> {
    setOpenDriver(true);
  }
  const addDocuments = ()=> {
    setOpenDocuments(true);
    setInputActive(true)
  }
  const addDriverSelected = ()=> {
    DriverApiInvoker.postAddDriverToVehicle(location.state.mode === "create" ? dataVehicle.vehicleId : params.id,driverSelected,{}, (response) => {
      setShowConfirmationAddDriver(true)
      
    }, (error) => {
      
      console.error('** error', error);
    });
    setOpenDriver(false);
  }

  const removeDriver = (idPerson)=>{
   
    DriverApiInvoker.deleteDriverToVehicle(idPerson,  () => {
      setShowConfirmationRemoveDriver(true)
    }, () => {
      console.log("Error *")
    });
  }
  const confirmAddDriver = ()=>{
    getAssignedDriver()
    setShowConfirmationAddDriver(false)
  }
  const confirmRemoveDriver = ()=>{
    getAssignedDriver()
    setShowConfirmationRemoveDriver(false)
  }
  const saveVehicle= ()=>{
    

     console.log(dataVehicle);
     DriverApiInvoker.putEditVehicle(dataVehicle, (response) => {
      if(location.state.mode === "create"){
        const idCompany = JSON.parse(localStorage.getItem('company'))?.company_id || null;
        const body = {
          companyPartnership: idCompany,
          vehicleId: dataVehicle.vehicleId,
          active: true
      }
        DriverApiInvoker.putEditVehicle(body, (response) => {
          goBack();
        }, 
        (error) => {
        
          console.error('** error', error);
        });
      }
      

      goBack();
    }, (error) => {
      
      console.error('** error', error);
    });
  }
  const saveDocument = (document)=>{
    setDataVehicle((prevDataVehicle) => ({
      ...prevDataVehicle,
      [documentSelected]: document,
    }));
    setInputActive(false)

  }
  const removeDocument = (document)=>{
    setDataVehicle((prevDataVehicle) => ({
      ...prevDataVehicle,
      [document]: null,
    }));
    setInputActive(true)
    setOpenDocuments(false);
    setDocumentSelectedEdit(null)
  }

  const editDocument = (type)=>{
    setDocumentSelectedEdit(type)
    addDocuments()
  }


  return (
    <>
      <Snackbar
        open={showSnackbar.open}
        color={showSnackbar.color}
        message={showSnackbar.message}
        place="tr"
      ></Snackbar>
      {loading ?
        <FullLoading /> : 
        <GridContainer className="edit-customer employee">
            <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader icon>
                <CardIcon color="secondary">
                  <DriversIcon />
                </CardIcon>
                <h3 className="card-icon-title">{t('vehicle.new.profileData')} </h3>
              </CardHeader>
              <CardBody className="edit-customer-form">
                <VehicleInfo
                  data={{
                    models, vehicle, brands, types, wheelChairSuitable, 
                    mode: formSettings.mode, save: true,
                  }}
                  active={dataVehicle.vehicleId}
                  handleDriverValue={handleDriverValue}
                  saveDriver={submitDriver}
                />
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader icon>
               
                <div className="header-internment-table">
                  <div className="header-icon vehicle-title">
                      <CardIcon color="primary">
                      <Person />
                      </CardIcon>
                      <h3 className="card-icon-title">{t('vehicle.new.driversData')}</h3>
                  </div>
                    {mode !== "view" ?
                    <div className="header-buttons">
                    <Tooltip title={t("vehicle.add.driver.title")}>
                        <span>
                        <Button
                            className="add-content-button appointments-button"
                            round
                            color="primary"
                            authority="appointment_new"
                            onClick={() => addDriver()}
                            disabled={dataVehicle.vehicleId ? false: true}
                        >
                            <Add className="appointments-button-icon" />{" "}
                            {t("vehicle.add.driver")}
                        </Button>
                        </span>
                    </Tooltip>
                    </div>
                    : 
                    <></>
                    }
                </div>
              </CardHeader>
              <CardBody className="edit-customer-form">
              <Table
                  striped
                  filterable
                  tableHeaderColor="primary"
                  tableHead={[
                    { Header: 'Apellido', accessor: 'lastName' },
                    { Header: 'Nombre', accessor: 'firstName' },
                    { Header: 'Identificación', accessor: 'identification' },
                    { Header: 'Email', accessor: 'email' },
                    { Header: 'Zona geográfica', accessor: 'geograficZone' },
                    { Header: 'Acciones', accessor: 'actions', Cell: ( row ) => {
                      
                      return(
                      
                      <div className="appointments-actions">
                        {mode !== "view" ?
                        <Tooltip title={t('common.remove')}>
                        <span>
                        <Button
                          simple
                          justIcon
                          color="danger"
                          onClick={()=>removeDriver(row.original.idDriver)}
                          id="ver"
                        ><Trash />
                        </Button>
                        </span>
                        </Tooltip>
                        : <></>
                      }
                      </div>
                      
                    )} },
                  ]}
                  tableData={driversVehicle}
                  defaultPageSize={10}
                  colorsColls={['primary']}
                />
              </CardBody>
            </Card>
          </GridItem>
          {/* DOCUMENTS */}
        <GridItem xs={12} className="specialities-practices-view">
          <Card className="employee-phones">
            <CardHeader icon>
                
                <div className="header-internment-table">
                  <div className="header-icon vehicle-title">
                      <CardIcon color="secondary">
                          <TextSnippet  />
                      </CardIcon>
                      <h3 className="card-icon-title">{t("vehicle.document.title")}</h3>
                  </div>
                  {mode !== "view" ?
                    <div className="header-buttons">
                    <Tooltip title={t("vehicle.add.document.title")}>
                        <span>
                        <Button
                            className="add-content-button appointments-button"
                            round
                            color="primary"
                            authority="appointment_new"
                            onClick= {()=>addDocuments()}
                            disabled={dataVehicle.vehicleId ? false: true}
                        >
                            <Add className="appointments-button-icon" />{" "}
                            {t("vehicle.add.document")}
                        </Button>
                        </span>
                    </Tooltip>
                    </div>
                    : <></>
                  }
                </div>
            </CardHeader>
            <CardBody>
                <Table
                  striped
                  filterable
                  tableHeaderColor="primary"
                  tableHead={[
                    { Header: 'Tipo', accessor: 'type' },
                    { Header: 'Acciones', accessor: 'actions', Cell: (row) =>{ 
                      return (
                      <div className="appointments-actions">
                        {row.original.active ?
                        <>
                        <Tooltip title={t('common.download')}>
                        <span>
                          <a href={row.original.url} download="file" style={{ textDecoration: 'none' }}>
                            <Button simple justIcon color="primary" id="Descargar">
                              <Assignment />
                            </Button>
                          </a>
                        </span>
                        </Tooltip>
                        
                        {
                          location.state.mode !== "view" ?
                          <Tooltip title={t('common.edit')}>
                          <span>
                          <Button
                            simple
                            justIcon
                            color="success"
                            onClick = {()=>editDocument(row.original.name)}
                            id="editar"
                          ><Edit />
                          </Button>
                          </span>
                          </Tooltip> : <></>
                        }
                        </>
                        : <></>
                      }
                      </div>
                    )} },
                  ]}
                  tableData={documents}
                  defaultPageSize={10}
                  colorsColls={['primary']}
                />
            </CardBody>
          </Card>
        </GridItem>        
            <GridItem xs={10} sm={10} md={10}  >
              <Button onClick={goBack}>
                {t('appointment.new.back')}
              </Button>
            </GridItem>
            {
              location.state.mode !== "view" ?
              <GridItem xs={2} sm={2} md={2} style={{ textAlign: "right" }}>
                  <Button
                    color="blue"
                    onClick={() => saveVehicle()}
                    className="align-right"
                  >
                    {t("vehicle.save")}
                  </Button>
                
            </GridItem>
            : <></>
            }
            <CustomDialog
                    title={t('vehicle.new.modal.driver')}
                    open={openDriver}
                    onClose={() => {
                        setPhone(null); 
                        setOpenDriver(false);
                    } }
                >
                  <div>
                    <GridContainer
                      className={"ps-child modules-form"}
                    >
                      <GridItem xs={12} sm={12} md={12} className="componentType">
                      <SelectInput
                          label="Chofer"
                          elements={drivers}
                          onSelectedValue={(value) =>
                            setDriverSelected(value)
                          }
                          
                        />
                        <CardFooter className="schedule-form-submit buttonFooter">
                          <Button
                            className="buttonPlusFooter"
                            onClick={() => addDriverSelected()}
                            color="primary"
                            
                          >{t('vehicle.new.add.driver')}
                          </Button>
                        </CardFooter>
                      </GridItem>
                    </GridContainer>
                  </div>
                    
            </CustomDialog>
            
            <CustomDialog
                    title={t('vehicle.new.modal.document')}
                    open={openDocuments}
                    onClose={() => {
                        setOpenDocuments(false);
                        setDocumentSelectedEdit(null)
                    } }
                >
                  <div>
                    
                    <GridItem xs={12} sm={12} md={12} className="componentType">
                      {inputActive && !documentSelectedEdit ? 
                        <SelectInput
                          label="Tipo documento"
                          elements={documentsType}
                          onSelectedValue={(value) =>
                            setDocumentSelected(value)
                          }
                        />
                        : <></>
                        }
                        <UpdateDownImage
                          saveDocument = {saveDocument}
                          removeDocument = {removeDocument}
                          initialFile={documentSelectedEdit}
                        />
                      </GridItem>
                  </div>
                    
            </CustomDialog>
                {showConfirmationAddDriver &&
                <CustomSweetAlert
                  type="success"
                  onConfirm={()=>confirmAddDriver()}
                  confirmBtnCssClass="primary"
                  title={t('driver.vehicle.created.confirmation.title')}
                  confirmBtnText={t('appointment.created.cancel.confirmation.OK')}
                  showCancel={false}
                  message={<p> {t('driver.vehicle.created.confirmation.message')}</p>}
                />
              }
              {showConfirmationRemoveDriver &&
                <CustomSweetAlert
                  type="success"
                  onConfirm={()=>confirmRemoveDriver()}
                  confirmBtnCssClass="primary"
                  title={t('driver.vehicle.remove.confirmation.title')}
                  confirmBtnText={t('appointment.created.cancel.confirmation.OK')}
                  showCancel={false}
                  message={<p> {t('driver.vehicle.remove.confirmation.message')}</p>}
                />
              }
        </GridContainer>
      }
    </>
  )
}

Driver.propTypes = {
  onGetEmployee: PropTypes.object,
  location: PropTypes.object, 
  params: PropTypes.object,   
  t: PropTypes.func, 
}

export default withTranslation()(Driver);
